import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline } from 'images/koppelingen'
import { employes } from 'images'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { eitjeLogoWhite, eitjeScreenshot } from 'images/koppelingen'
import Video from "components/video"

const dummyKlantVerhaal = {
  content: [{
    title: 'Over eitje',
    description: 'Het hoeft allemaal niet zo ingewikkeld te zijn om snel een goede personeelsplanning te maken. Met eitje haal jij de gemakkelijkste planning & urenregistratie tool van Nederland in huis, zonder in te boeten op functionaliteit. Zo kun jij zonder enige moeite snel starten met je eigen team en heb jij in no-time je planning online. Verder is eitje niet alleen de tool voor je personeelsplanning, maar brengen wij alles voor je teamleden bij elkaar. Zo sta jij in direct contact met je werknemers met onze communicatie tools, ervaar jij het gemak van een compleet digitaal kenniscentrum en komen daar dus nog de planning & urenregistratie functionaliteiten bij. Wel zo eenvoudig ;)'
    }, {
      title: 'De werking van de koppeling met eitje',
      description: 'De koppeling tussen Employes en eitje zorgt ervoor dat jij niet meer een dubbele administratie hoeft te voeren. Elke dag komen de laatste wijzigingen van je teamleden en hun contractgegevens bij ons binnen. Mocht er dus een wijziging in het bruto uurloon van een teamlid zijn geweest. Dezelfde dag halen wij dit op en staat alles in eitje weer helemaal correct.',
    },{
      title: 'Functies van de koppeling',
      list: ['Teamleden importeren en aanmaken','Contract informatie importeren']
    }, {
      image: eitjeScreenshot
    }, {
      title: 'Tarieven van de koppeling',
      description: 'De koppeling tussen Employes en eitje is geheel gratis!'
  }]
}

class eitje extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

        <Helmet>
          <title>Koppeling eitje & Employes | Salarisadministratie</title>
          <meta name="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van eitje."/>
          <meta itemprop="name" content="Koppeling eitje & Employes | Salarisadministratie" />
          <meta itemprop="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van eitje."/>
          <meta itemprop="image" content="/static/meta-img.png" />
        </Helmet>

          <header className="koppeling eitje padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel eitje aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline} alt="employes logo"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={eitjeLogoWhite} alt="Treams logo"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const isVideo = (firstObjectKey === 'video')
                  const { title, description, list } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isVideo ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isVideo ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                {description && (<p>{description}</p>)}
                                {list && list.length && (<ul>{list.map(point => (<li>{point}</li>))}</ul>)}
                              </div>
                            ),
                            image: (
                              <img src={section.image} alt="section" />
                            ),
                            video: (
                                <Video videoSrcURL={section.video} videoTitle="Koppeling Moneybird en Employes salarisadministratie"/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <a href="https://www.eitje.app" target="_blank" rel="nofollow">Lees meer over eitje</a>
                </div>
              </div>
            </div>
          </div>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

eitje.propTypes = {
  data: PropTypes.object
}

export default eitje
